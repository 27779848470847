<template>
    <v-container>
        <v-row>
            <v-col cols=6><h2 class="primary--text mb-2" style="font-weight:400;">Project Tasks</h2> </v-col>
            <v-col cols=6 class="text-right"><p class="primary--text">{{ now_date }}</p></v-col>
        </v-row>
        <v-row style="margin-top:-25px;">
            <v-col cols=8><h4 class="primary--text mb-0">{{ project.name }}</h4><h4 class="primary--text mb-2">{{ project.project_code }}</h4> </v-col>
            <v-col cols=4 class="text-right"><h4 class="primary--text">{{ progress }}%</h4></v-col>
        </v-row>
        <div v-if="project.tasks.length > 0">
        <v-card
            elevation="2"
            outlined
            class="blue lighten-4">
            <v-card-title>
                <h4 class="primary--text">Project Tasks</h4>
            </v-card-title>
            <v-card-text style="padding-bottom:0px;">
                <v-row v-for="(task,index) in project.tasks" v-bind:key="task.id" style="margin-top:-10px;">
                    <v-col cols="7"><h4 class="black--text">{{ (index+1) }}. {{ task.name }}</h4></v-col>
                    <v-col cols="1">
                        <v-icon v-if="task.status == 'complete'" class="primary--text">mdi-sticker-check</v-icon>
                        <!-- <v-icon  class="primary--text">mdi-square-rounded</v-icon> -->
                        <v-checkbox v-else label="" style="padding-top:0px !important;" color="primary" value="check" hide-details></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                        <v-btn v-if="task.status != 'complete'" x-small class="green" @click="submitTools(task.id, project.id)">Submit</v-btn>
                        <v-btn v-if="task.status == 'complete'" x-small class="primary">Submitted</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </div>
        <div v-else>
            <v-alert type="error">No project task found</v-alert>
        </div>
    </v-container>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
import date from 'date-and-time'
import Swal from 'sweetalert2'
export default {
    name:'ProjectSummary',
    data: () => ({
        now_date : "",
        user: JSON.parse(localStorage.getItem('user'))
    }),
    computed: {
        //... mapGetters ( {project : "getCurrentProject"})
        ...mapGetters (["getCurrentProject","getTaskProgress" ]),
        project () {
            return this.getCurrentProject
        },
        progress () {
            return this.getTaskProgress
        }
    },
    mounted(){
        let now  = new Date();
        let now_dates = date.format(now, 'ddd, MMM DD YYYY');
        this.now_date = now_dates 
        
        if(this.user == null){
            this.$router.push('/')
        }else{
            if(this.user.progress != 1){
                this.$router.push('/home')
            }
        }
    },
    methods: {
        ... mapActions (['fetchAllProjects']),
        async submitTools (value,project_id) {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    task: value,
                    user: 1,
                    project: project_id,
                })
            };
            const res = await fetch('https://camusatweb.ubuniworks.com/api/v1/projects/task', requestOptions)
            const data = await res.json()
            if(data.status == true){          
                Swal.fire({
                    icon: 'success',
                    title: 'Project Task successfully submitted',
                    text: 'You will be informed once it has been reviewed'
                }).then(() => {
                    this.$router.push('/projects')
                })        
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Project Task failed',
                    text: data.message
                })
            }
        }
        
    }

}
</script>

<style scoped>
p{
    margin-bottom: 0px;
}
.v-input--selection-controls {
    margin-top: 0px;
}
</style>